import { makeStyles } from '@mui/styles';
import { Colors } from '@cma-cgm/cma-ui';

const useStyles = makeStyles(() => ({
    mainPage: {
        minHeight: 'calc(100vh - 92.41px)',
        backgroundColor: Colors.colorBackgroundDefault,
        padding: '107.41px 0 15px 0'
    },
    video: {
        width: 'auto',
        height: '70vh',
        '@media (max-width: 940px)': {
            width: '90%',
            height: 'auto'
        }
    }
}));

export default useStyles;