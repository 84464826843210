import { CmaGrid } from '@cma-cgm/cma-ui';
import useStyles from './ImageGalleryPageStyles';
import ImageGallery from 'react-image-gallery';
import './ImageGallery.scss';
import 'react-image-gallery/styles/css/image-gallery.css';
import { images } from './ImageGalleryConstants';

const ImageGalleryPage = () => {
    const classes = useStyles();

    const galleryOptions = {
        showFullscreenButton: false,
        showPlayButton: false,
        showNav: true,
        thumbnailClass: classes.customThumbnail,
        renderItem: (item: any) => (
            <div>
                <img
                    src={item.original}
                    alt={item.description}
                    style={{ width: '85vw', height: '60vh', objectFit: 'cover' }}
                />
            </div>
        ),
        renderThumbInner: (item: any) => (
            <div style={{ position: 'relative', width: '100px', height: '60px' }}>
                <img
                    src={item.thumbnail}
                    alt={item.description}
                    className={classes.thumbnails}
                />
            </div>
        )
    };

    return (
        <CmaGrid container display='flex' justifyContent='center' alignItems='center' className={classes.mainPage}>
            <ImageGallery items={images} {...galleryOptions} />
        </CmaGrid>
    );
};

export default ImageGalleryPage;