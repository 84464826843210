import { makeStyles } from '@mui/styles';
import { Colors } from '@cma-cgm/cma-ui';

const useStyles = makeStyles(() => ({
    mainPage: {
        minHeight: 'calc(100vh - 92.41px)',
        backgroundColor: Colors.colorBackgroundDefault,
        padding: '107.41px 0 10px 0'
    },
    title: {
        color: `${Colors.colorFillPrimaryBlue} !important`,
        height: '100px !important',
        marginBottom: '10px !important'
    },
    customThumbnail: {
        maxWidth: '200px',
        overflowX: 'scroll'
    },
    thumbnails: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '92%',
        height: '100%',
        objectFit: 'cover',
        '@media (max-width: 775px)': {
            width: '76%'
        }
    }
}));

export default useStyles;