import { makeStyles } from '@mui/styles';
import { Colors } from '@cma-cgm/cma-ui';

const useStyles = makeStyles(() => ({
    mainPage: {
        minHeight: 'calc(100vh - 92.41px)',
        backgroundColor: Colors.colorBackgroundDefault,
        padding: '107.41px 0 15px 0'
    },
    title: {
        color: `${Colors.colorFillPrimaryBlue} !important`,
        marginBottom: '10px !important'
    },
    card: {
        minWidth: '250px',
        maxWidth: '250px',
        height: '150px',
        cursor: 'pointer',
        border: `1px solid ${Colors.colorFillSecondaryGreyRegular}`,
        paddingLeft: '0 !important'
    },
    divider: {
        backgroundColor: `${Colors.colorFillPrimaryRed} !important`,
        width: '70%'
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        color: `${Colors.colorFillPrimaryBlue} !important`,
        fontSize: '14px !important',
        fontWeight: '600 !important'
    }
}));

export default useStyles;