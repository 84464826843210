export const aboutList = [
    {
        id: 1,
        title: 'History',
        text: 'Established in 2001, Terminal Link is a global leading port investor, developer and terminal operator.\nBased in Marseille, Terminal Link is a company belonging to CMA CGM 51% and China Merchants Port Holding Company Ltd 49%.'
    },
    {
        id: 2,
        title: 'Our Company',
        image: '/images/about2.jpg',
        text: 'Terminal Link offers a comprehensive network of ports around the world. The group is present in 4 continents, 16 countries and 21 terminals, strategically located in the most important worldwide markets: Europe & Africa, Asia & Middle East and Americas.\nTerminal Link is the result of the combined deep expertise of two world leaders: CMA CGM and China Merchants Port. Taping on the recognized experience and knowledge of its shareholders, Terminal Link is today a global brand synonymous of efficient, safe and reliable operations with a continuous focus on improvement. The expertise, experience and dedication of Terminal Link team, provides high added-value services which helps our customers, shipping Lines and landside customers, to design and optimize efficient supply chains in the most important markets worldwide.\nAt Terminal Link, we pride ourselves for our excellent relationship with customers, and our continuous joint effort to find better ways to serve our customers and partners.'
    },
    {
        id: 3,
        title: 'Our Shareholders',
        image: '/images/about3.jpg',
        text: 'CMA CGM, is a major global player in sea, land, air and logistic solutions, operating in 160 countries.\nThrough its two port subsidiaries, CMAT and Terminal Link, CMA CGM Group offers a global network of 50 terminals around the world, with presence in the key markets in Europe, Africa, Asia and Americas (https://www.cma-cgm.com)\nCMPort is a globally competitive public port developer, investor and operator. CMPort has broadened its focus from China to global market and now owns a port network portfolio spanning in 42 ports in 25 countries and regions (https://www.cmport.com.hk)'
    },
    {
        id: 4,
        title: 'Vision',
        image: '/images/about4.jpg',
        text: 'Continuously and consistently strengthen our position to become a world leading terminal operator to provide best service to wider logistics, container shipping and maritime industry clients\nProvide the best solutions and services to our customers, building sustainable supply chains'
    },
    {
        id: 5,
        title: 'QHSSE',
        image: '/images/about5.jpg',
        text: 'Our commitment is to provide safe operations with minimal impact to the environment in which we operate, protecting our people, customers and stakeholders.\nWe comply with strict HSSE rules, and our aim is to achieve ZERO accidents in our terminals.'
    }
    // {
    //     id: 6,
    //     title: 'Management',
    //     text: ''
    // }
];