import { CmaGrid, CmaTypography, Colors } from '@cma-cgm/cma-ui';
import useStyles from './HomePageStyles';
import './Button.scss';
import { useNavigate } from 'react-router-dom';
import TerminalBox from './TerminalBox/TerminalBox';
import { aboutList } from '../About/AboutConstants';
import { useEffect } from 'react';
import ResponsiveSlider from './ResponsiveSlider/ResponsiveSlider';

const HomePage = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClick = () => {
        navigate('/about');
    };

    return (
        <CmaGrid container justifyContent='center' className={classes.mainPage}>
            <CmaGrid item container xs={12} justifyContent='center'>
                {/* Banner */}
                <CmaGrid item xs={12}>
                    <ResponsiveSlider />
                </CmaGrid>

                {/* About Us */}
                <CmaGrid
                    item
                    container
                    xs={12}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    gap={2}
                    paddingY={4}>
                    <CmaGrid item xs={10} sm={8} textAlign='center'>
                        <CmaTypography variant='h1' textAlign='center' className={classes.text}>
                            About Us
                        </CmaTypography>
                    </CmaGrid>

                    <CmaGrid item xs={10} sm={8} textAlign='center'>
                        <CmaTypography className={classes.text}>
                            Taping on the recognized experience and knowledge of its shareholders, Terminal Link is
                            today a global brand synonymous of efficient, safe and reliable operations with a
                            continuous focus on improvement. The expertise, experience and dedication of
                            Terminal Link team, provides high added-value services which helps our customers,
                            shipping Lines and landside customers, to design and optimize efficient supply chains
                            in the most important markets worldwide. …
                        </CmaTypography>

                        <a className='style-button primary' onClick={handleClick}>
                            <span>More About Us</span>
                        </a>
                    </CmaGrid>
                </CmaGrid>

                {/* About */}
                <CmaGrid
                    item
                    container
                    xs={12}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    flexWrap='wrap'
                    gap={4}
                    paddingY={4}
                    bgcolor={Colors.colorFillSecondaryWhite}>
                    {aboutList.filter(listItem => ['History', 'Management'].indexOf(listItem.title) === -1).map((listItem) =>
                        <TerminalBox
                            key={listItem.id}
                            id={listItem.id}
                            title={listItem.title}
                            image={listItem.image}
                            content={(listItem.title !== 'Our Shareholders') ?
                                listItem.text :
                                'Terminal Link is the result of the combined deep expertise of two world leaders: CMA CGM and China Merchants Port.'}
                        />
                    )}
                </CmaGrid>
            </CmaGrid>
        </CmaGrid>
    );
};

export default HomePage;