import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import tablesReducer from './features/tables/tablesSlice';

export default configureStore({
	reducer: {
		tables: tablesReducer
	},
	middleware: getDefaultMiddleware({
		serializableCheck: false
	})
});