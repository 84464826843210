import React, { useRef, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { headerList } from './HeaderConstants';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import useStyles from './HeaderStyles';
import clsx from 'clsx';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CSSTransition } from 'react-transition-group';
import { CmaTab, CmaTabs } from '@cma-cgm/cma-ui';

function Header() {
	const [selected, setSelected] = useState<number>(0);
	const [hovered, setHovered] = useState<boolean>(false);
	const [menuHovered, setMenuHovered] = useState<boolean>(false);
	const [state, setState] = useState<any>({ id: 0, list: [] });
	const [currentLocation, setCurrentLocation] = useState<string>('');

	const [expanded, setExpanded] = useState<number>(0);
	const [mobileOpen, setMobileOpen] = useState<boolean>(false);

	const [showMenu, setShowMenu] = useState(false);
	const nodeRef = useRef(null);

	interface TabInterface {
		id: number,
		value: number
	}

	const initialValue: TabInterface[] = headerList.filter((headerListItem) => headerListItem.list.length > 0)
		.map((listItem) => ({ id: listItem.id, value: 0 }));

	const [value, setValue] = useState<TabInterface[]>(initialValue);
	const [tabValue, setTabValue] = useState<TabInterface[]>(initialValue);
	const [isTransitionClosing, setIsTransitionClosing] = useState<boolean>(false);

	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	console.error = () => { return <></>; };
	const handleTabHover = (id: number, index: number) => {
		const updatedValue = value.map((valueItem) => {
			if (valueItem.id === id) {
				return { id: id, value: index };
			} else {
				return valueItem;
			}
		});

		!isTransitionClosing && setValue(updatedValue);
	};

	const handleTabClick = (id: number, index: number) => {
		const updatedTabValue = tabValue.map((tabValueItem) => {
			if (tabValueItem.id === id) {
				return { id: id, value: index };
			} else {
				return tabValueItem;
			}
		});

		setTabValue(updatedTabValue);
	};
	const handleClick = (url: string) => {
		setSelected(0);
		setState({ id: 0, list: [] });
		navigate(url);
	};

	const handleMouseEnter = (id: number, list: any) => {
		if (list && list.length > 0) {
			setSelected(id);
			setState({ ...state, id, list });
		} else {
			setSelected(0);
		}
	};

	const handleToggle = () => {
		setMobileOpen((prevState: boolean) => !prevState);
	};

	useEffect(() => {
		setShowMenu(false);

		if (selected !== 0) {
			const timer = setTimeout(() => {
				setShowMenu(true);
			}, 0);
			return () => clearTimeout(timer);
		}
	}, [selected]);

	useEffect(() => {
		!menuHovered && setSelected(0);
	}, [menuHovered]);

	useEffect(() => {
		setCurrentLocation(location.pathname.split('/')[1]);
		if ((headerList.filter((list) => list.url.split('/')[1] === currentLocation)[0]?.list.length > 0) && (currentLocation !== location.pathname.split('/')[1])) {
			const updatedValue = value.map((valueItem) => {
				if (valueItem.id === headerList.filter((list) => list.url.split('/')[1] === currentLocation)[0]?.id) {
					return { id: valueItem.id, value: 0 };
				} else {
					return valueItem;
				}
			});
			setValue(updatedValue);
		}
	}, [location.pathname.split('/')[1]]);

	return (
		<>
			{state.list &&
				<div className={selected === state.id && state.id !== 0 ? (state.id === 2 ? clsx(classes.open, classes.aboutListWrapper) : clsx(classes.open, classes.portsListWrapper)) : (state.id === 2 ? classes.aboutListWrapper : classes.portsListWrapper)}
					onMouseEnter={() => { setMenuHovered(true); }}
					onMouseLeave={() => { setMenuHovered(false); }}>
					<CSSTransition
						in={showMenu}
						nodeRef={nodeRef}
						classNames='submenu-title'
						timeout={300}
						onEnter={() => { setIsTransitionClosing(false); }}
						onExit={() => { setIsTransitionClosing(true); }}
						unmountOnExit>
						<div ref={nodeRef}>
							<CmaTabs
								orientation='vertical'
								value={value.filter(valueItem => valueItem.id === state.id)[0]?.value}
								variant='standard'
								TabIndicatorProps={{
									style: { left: 0 }
								}}
								sx={{
									borderRight: '0 !important',
									position: 'relative'
								}}
							>
								{state.list.map(({ id, title, url }: any, index: number) => (
									<CmaTab
										key={index}
										label={title}
										onClick={() => { setSelected(0); navigate(url); handleTabClick(state.id, index); }}
										onMouseOver={() => handleTabHover(state.id, index)}
										onMouseOut={() => handleTabHover(state.id, tabValue.filter(tab => tab.id === state.id)[0].value)}
									/>
								))}
								<div className={classes.line}></div>
							</CmaTabs>
						</div>
					</CSSTransition>
				</div >}

			<AppBar component='nav' sx={{ backgroundColor: 'white' }}>
				<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}
					onMouseEnter={() => { setMenuHovered(true); }}
					onMouseLeave={() => { setMenuHovered(false); }}>
					<IconButton onClick={handleToggle} sx={{ display: { xs: 'flex', md: 'none' } }}>
						<MenuIcon />
					</IconButton>

					<img
						src='/terminalLinkLogo.png'
						className={mobileOpen ? classes.headerLogoHidden : classes.headerLogo}
						onClick={() => {
							navigate('/');
							setSelected(0);
							setState({ id: 0, list: [] });
							setExpanded(1);
						}}
					/>

					{/* For Desktop */}
					<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
						{headerList.map(({ id, title, url, list }) => (
							<a
								key={id}
								className={clsx((url.split('/')[1] === location.pathname.split('/')[1]) ?
									clsx(classes.active, classes.headerLinks) : classes.headerLinks, hovered ? classes.hoveredList : '')}
								onMouseEnter={() => { setHovered(true); handleMouseEnter(id, list); }}
								onMouseLeave={() => { setHovered(false); }}
								onClick={() => handleClick(url)}>
								{title}
							</a>
						))}
					</Box>
				</Toolbar>

				{/* For Mobile */}
				<Box component='nav'>
					<Drawer
						variant='temporary'
						open={mobileOpen}
						onClose={handleToggle}
						sx={{
							'& .MuiDrawer-paper': { width: '350px' }
						}}>
						<Box sx={{ textAlign: 'center' }}>
							<img src='/terminalLinkLogo.png' className={classes.sideMenuLogo} />

							<Divider />
							{headerList.map((listItem) => (
								<Accordion
									key={listItem.id}
									expanded={listItem.list.length > 0 && expanded === listItem.id}
									onChange={() => {
										setExpanded((expanded === listItem.id) ? 0 : listItem.id);
										if (listItem.list.length === 0) {
											navigate(listItem.url);
											setMobileOpen(false);
										}
									}}>
									<AccordionSummary expandIcon={(listItem.list.length > 0) ? <ExpandMoreIcon /> : ''}>
										<NavLink
											to={listItem.url}
											className={expanded === listItem.id ?
												clsx(classes.drawerMainLinks, classes.active) :
												classes.drawerMainLinks}>
											{listItem.title}
										</NavLink>
									</AccordionSummary>
									<AccordionDetails>
										{listItem.list.length > 0 && listItem.list.map((subListItem, index) => (
											<div className={classes.linksWrapper} key={index}>
												<NavLink
													to={subListItem.url}
													style={{ textDecoration: 'none' }}
													onClick={() => { (listItem.list.length > 0) && setMobileOpen(false); }}>
													{subListItem.title}
												</NavLink>
											</div>
										))}
									</AccordionDetails>
								</Accordion>
							))}
						</Box>
					</Drawer>
				</Box>
			</AppBar>

		</>
	);
}
export default Header;