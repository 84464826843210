import { CmaGrid, CmaTypography } from '@cma-cgm/cma-ui';
import useStyles from './LocationPageStyles';
import { continentsList } from '../SelectLocation/SelectLocationsConstants';
import { useEffect } from 'react';

const LocationPage = () => {
    const classes = useStyles();

    const continentId = Number(location.pathname.split('/')[3].charAt(0));
    const countryId = Number(location.pathname.split('/')[3].substring(0, 2));
    const portId = Number(location.pathname.split('/')[3]);

    const content: any = continentsList.filter((continent) => continent.id === continentId)[0]
        .countriesList.filter((country) => country.id === countryId)[0]
        .portsList.filter((port) => port.id === portId)[0];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <CmaGrid container display='flex' direction='column' className={classes.mainPage}>
            <CmaGrid item xs={2} sx={{ paddingLeft: '36px' }} marginBottom={2}>
                <CmaTypography variant='h1' className={classes.title}>
                    {content.content.title}
                </CmaTypography>
            </CmaGrid>
            <CmaGrid item container xs={11} gap={2} display='flex' justifyContent='space-evenly'>
                {/* Left */}
                <CmaGrid item container xs={11} sm={5} md={2.5} className={classes.section}>
                    <CmaGrid item xs={12}>
                        <img
                            src={content.content.image}
                            alt=''
                            className={classes.terminalCharacteristics}
                        />
                    </CmaGrid>

                    <CmaGrid item container xs={12}>
                        <CmaTypography variant='h3' className={classes.title}>
                            Terminal characteristics
                        </CmaTypography>

                        {content.content.terminalCharacteristics.map((item: any, index: number) => (
                            <CmaGrid key={index} item container xs={12}>
                                <CmaGrid item xs={12}>
                                    <CmaTypography variant='h4' className={classes.subtitle}>
                                        {item.name}
                                    </CmaTypography>
                                </CmaGrid>
                                <CmaGrid item xs={2}>
                                    <img className={classes.icon} src={item.icon} alt='' />
                                </CmaGrid>
                                <CmaGrid item xs={6}>
                                    {Object.entries(item.values).map(([key, value], index) =>
                                        <CmaTypography key={index} variant='caption' className={classes.subtitle}>
                                            <span className={classes.dash}>—</span> {value as any}
                                        </CmaTypography>
                                    )}
                                </CmaGrid>
                            </CmaGrid>
                        ))}
                    </CmaGrid>
                </CmaGrid>

                {/* Right */}
                <div className={classes.content}>
                    {content.image && (
                        <div>
                            <img
                                className={(content.name === 'Singapore') ? classes.singaporeImage : classes.mainImage}
                                src={content.image}
                                alt=''
                            />
                        </div>
                    )}

                    <div>
                        {content.advantages &&
                            <>
                                <CmaTypography variant='h3' className={classes.title}>
                                    Key advantages
                                </CmaTypography>

                                {content.advantages.split('\n').map((line: string, index: number) => (
                                    <CmaTypography key={index} variant='body1' className={classes.text}>
                                        {(line.length > 0) && <span className={classes.dash}>—</span>}  {line}
                                    </CmaTypography>
                                ))}
                            </>}

                        {content.markets &&
                            <>
                                <CmaTypography variant='h3' marginTop={2} className={classes.title}>
                                    Markets served
                                </CmaTypography>

                                {content.markets.split('\n').map((line: string, index: number) => (
                                    <CmaTypography key={index} variant='body1' className={classes.text}>
                                        {(line.length > 0) && <span className={classes.dash}>—</span>}  {line}
                                    </CmaTypography>
                                ))}
                            </>}
                    </div>
                </div>
            </CmaGrid>
        </CmaGrid>
    );
};

export default LocationPage;