export const careerList = [
    // {
    //     id: 521967,
    //     location: 'Marseille, FR',
    //     title: 'Chief Commercial Officer',
    //     description: 'Terminal Link is a port operator used to develop, design, build and operate the terminal. It originated with a development strategy of providing terminals with a high quality service for every shipping company, optimising the financial value and profitability of each terminal and maintain a diverse customer portfolio.\nTerminal Link is part of the successful CMA CGM Group, the world\'s 3rd largest container shipping company.',
    //     content: {
    //         'THE ROLE': 'Within TERMINAL LINK (TL), the CCO will be responsible for the promotion and development of new business through its ports, optimising revenues and improving results of group assets\nUpon request, provide support on projects for Business Development (BD) and lead Marketing studies.',
    //         'RESPONSIBILITIES': '\tSupport Terminals in all commercial aspects, such as:\nPromoting TL ports as preferred port of call for CMA CGM. Leverage on Ocean Alliance partnership to develop volume from Alliance partners at TL facilities\nPromote TL ports to all other Shipping Lines, Freight Forwarders and key shippers and consignees\nSupport terminals to ensure growth of customer base and their business volume, while contributing to growing satisfaction of our customers\nSupport TL\'s terminals on the day-to-day commercial activities, including assistance on contract negotiations, whenever required\nDevelop and cultivate closer customer relationships, by continually exploring initiatives to improve business partnerships so as to encourage customers to retain Terminal Link as preferred business partner\nSupport terminals in the development of new solutions, which may consist in developing new supply chains with the aim of attracting new cargo flow through TL\'s ports\nParticipation in Marketing events.\n\tDeveloping relationship with Carriers from a global perspective:\nMonitoring customer performance and satisfaction\nIdentify opportunities to increase volumes\nPerform yearly review with customers to present opportunities and to study their future needs.\n\tSupporting other departments for all marketing aspects:\nPerform market analysis including traffic and services projections\nSupport the business development team with relevant market and competition analysis and all necessary commercial actions regarding new potential projects, contributing to the expansion of the portfolio of Terminal Link.',
    //         'PROFILE AND QUALIFICATIONS': 'Postgraduate degree (+5 years)\nMinimum 5 year commercial and/or operational experience in the Terminals and/or liner shipping business\nAutonomy and sense of initiative, interpersonal and communication skills\nResponsiveness, work ethics, team spirit and result-oriented\nFluency in English and in another major international language would be a plus.'
    //     },
    //     notes: 'You will report directly to the Managing Director. Some travel are necessary for this position.\nCome along on Terminal Link\'s adventure !'
    // }
];