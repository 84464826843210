export const images = [
    {
        original: '/images/Abidjan.jpg',
        thumbnail: '/images/Abidjan.jpg',
        description: 'Abidjan'
    },
    {
        original: '/images/Antwerp.jpg',
        thumbnail: '/images/Antwerp.jpg',
        description: 'Antwerp'
    },
    {
        original: '/images/Casablanca.jpg',
        thumbnail: '/images/Casablanca.jpg',
        description: 'Casablanca'
    },
    {
        original: '/images/Dunkrik.jpg',
        thumbnail: '/images/Dunkrik.jpg',
        description: 'Dunkrik'
    },
    {
        original: '/images/HoChiMinh.jpg',
        thumbnail: '/images/HoChiMinh.jpg',
        description: 'HoChiMinh'
    },
    {
        original: '/images/Houston.jpg',
        thumbnail: '/images/Houston.jpg',
        description: 'Houston'
    },
    {
        original: '/images/Kingston.jpg',
        thumbnail: '/images/Kingston.jpg',
        description: 'Kingston'
    },
    {
        original: '/images/LaemChabang.jpg',
        thumbnail: '/images/LaemChabang.jpg',
        description: 'LaemChabang'
    },
    {
        original: '/images/Malta.jpg',
        thumbnail: '/images/Malta.jpg',
        description: 'Malta'
    },
    {
        original: '/images/Marseille.jpg',
        thumbnail: '/images/Marseille.jpg',
        description: 'Marseille'
    },
    {
        original: '/images/Montoir.jpg',
        thumbnail: '/images/Montoir.jpg',
        description: 'Montoir'
    },
    {
        original: '/images/Qingdao.jpg',
        thumbnail: '/images/Qingdao.jpg',
        description: 'Qingdao'
    },
    {
        original: '/images/Rotterdam.jpg',
        thumbnail: '/images/Rotterdam.jpg',
        description: 'Rotterdam'
    }
];