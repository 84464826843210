import { CmaCard, CmaCardActions, CmaCardHeader, CmaDivider, CmaGrid, CmaTypography } from '@cma-cgm/cma-ui';
import useStyles from './CareerPageStyles';
import { careerList } from './CareerConstants';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Button } from '@mui/material';

const CareerPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleClick = (id: number) => {
        const path = location.pathname.concat(`/${id}`);
        navigate(path);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <CmaGrid container alignItems='center' justifyContent='center' className={classes.mainPage}>
            {/* <CmaTypography variant='h2' textAlign='center' className={classes.title}>
                Please keep following us and look forward to your joining
            </CmaTypography> */}

            <CmaGrid
                item
                container
                xs={11}
                md={10}
                lg={9}
                display='flex'
                direction={{ xs: 'column', md: 'row' }}
                alignItems='center'
                justifyContent={{ xs: 'center' }}
                gap={{ xs: 4, md: 2 }}
                flexWrap='nowrap'>
                {careerList.length > 0 ?
                    (careerList.map((careerItem: any) => (
                        <CmaCard key={careerItem.id} className={classes.card} onClick={() => { handleClick(careerItem.id); }}>
                            <CmaCardHeader title={<CmaTypography color='primary' variant='h3'>{careerItem.title}</CmaTypography>} />
                            <CmaDivider className={classes.divider} />
                            <CmaCardActions className={classes.cardActions}>
                                <Button sx={{ height: '40px', padding: '12px 24px' }} className={classes.button}>
                                    LEARN MORE
                                </Button>
                            </CmaCardActions>
                        </CmaCard>
                    ))) : (
                        <CmaTypography variant='h2' textAlign='center' sx={{ color: '#04246a !important' }}>
                            We don't have any open positions at the moment. <br />
                            Please remain updated for future opportunities.
                        </CmaTypography>
                    )
                }
            </CmaGrid>
        </CmaGrid>
    );
};

export default CareerPage;