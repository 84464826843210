import { CmaGrid, CmaTypography } from '@cma-cgm/cma-ui';
import useStyles from './FooterStyles';

const Footer = () => {
    const classes = useStyles();

    const year = new Date().getFullYear();

    return (
        <CmaGrid container display='flex' justifyContent='center' className={classes.footer}>
            <CmaGrid
                item
                xs={10}
                sm={6}
                display='flex'
                alignItems='center'
                justifyContent={{ xs: 'center', sm: 'flex-start' }}
                className={classes.footerItem}
            >
                <img src='/terminalLinkLogo.png' className={classes.icon} />
            </CmaGrid>

            <CmaGrid
                item
                xs={10}
                sm={6}
                display='flex'
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
                alignItems='center'
                className={classes.footerItem}>
                <CmaTypography className={classes.footerLink}>
                    © {year} TERMINAL LINK
                </CmaTypography>
            </CmaGrid>
        </CmaGrid >
    );
};

export default Footer;