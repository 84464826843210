import React from 'react';
import useStyles from './ResponsiveSliderStyles';
import './ResponsiveSlider.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { images } from './ResponsiveSliderConstants';

const CustomPrevArrow = (props: any) => {
    const { onClick, className } = props;
    return (
        <div className={`${className} custom-arrow custom-prev-arrow`} onClick={onClick}>
            Left
        </div>
    );
};

const CustomNextArrow = (props: any) => {
    const { onClick, className, style } = props;
    const lastSlideClass = className.includes('slick-disabled') ? 'hidden-arrow' : '';
    return (
        <div className={`${className} ${lastSlideClass} custom-arrow custom-next-arrow`} style={style} onClick={onClick}>
            Right
        </div>
    );
};

const ResponsiveSlider = () => {
    const classes = useStyles();

    const settings = {
        dots: false,
        dotsClass: 'slick-dots custom-dots',
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    };

    return (
        <Slider {...settings}>
            {images.map((image: string) => (
                <div key={image}>
                    <img src={image} alt='' />
                </div>
            ))}
        </Slider>
    );
};

export default ResponsiveSlider;