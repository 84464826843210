import { CmaButton, CmaGrid, CmaTypography } from '@cma-cgm/cma-ui';
import useStyles from './TerminalBoxStyles';
import { useNavigate } from 'react-router-dom';

const TerminalBox = (props: { id: number; title: string; image?: string; content: string; }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const { id, title, image, content } = props;

    const handleClick = () => {
        navigate(`/about#${id}`);
    };

    return (
        <CmaGrid item justifyContent='center' className={classes.box}>
            <img
                src={image}
                className={classes.boxImage}
                alt=''
            />

            <CmaTypography variant='h4' marginLeft={2} marginTop={2} className={classes.title}>
                {title}
            </CmaTypography>

            <CmaTypography variant='body1' marginLeft={2} marginTop={2} className={classes.text}>
                {content}
            </CmaTypography>

            <CmaGrid item display='flex' justifyContent='flex-end'>
                <CmaButton size='small' onClick={handleClick} className={classes.button}>
                    READ MORE
                </CmaButton>
            </CmaGrid>
        </CmaGrid>
    );
};

export default TerminalBox;