import React, { useRef } from 'react';
import useStyles from './StructuredTreeStyles';
import { CmaGrid, CmaTypography } from '@cma-cgm/cma-ui';
import { Colors } from '@cma-cgm/cma-ui';
import Xarrow from 'react-xarrows';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const StructuredTreePage = () => {
    const classes = useStyles();

    const box1Ref = useRef(null);
    const box2Ref = useRef(null);
    const box3Ref = useRef(null);
    const box4Ref = useRef(null);
    const box5Ref = useRef(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <CmaGrid
            item
            container
            xs={12}
            display='flex'
            alignItems='center'
            direction={{ xs: 'row', md: 'column' }}
            gap={{ md: 4 }}>
            {/* Top */}
            <CmaGrid
                item
                xs={6}
                display='flex'
                alignItems='center'
                justifyContent={{ xs: 'flex-start', md: 'center' }}>
                <div ref={box1Ref} className={classes.parent}>
                    <CmaTypography variant='h3' className={classes.text}>President</CmaTypography>
                    <CmaTypography variant='body2' className={classes.text}>Christine CABAU</CmaTypography>
                </div>
            </CmaGrid>

            {/* Bottom */}
            <CmaGrid
                item
                gap={4}
                xs={6}
                width={{ xs: '100%', lg: '80%' }}
                sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
                alignItems='center'
                justifyContent='space-between'>
                <div ref={box2Ref} className={classes.node}>
                    <CmaTypography variant='h3' className={classes.text}>Chief Commercial Officer</CmaTypography>
                    <CmaTypography variant='caption' className={classes.text}>Commercial & Marketing</CmaTypography>
                    <CmaTypography variant='body2' className={classes.text}>TBD</CmaTypography>
                </div>
                <div ref={box3Ref} className={classes.node}>
                    <CmaTypography variant='h3' className={classes.text}>Chief Operations Officer</CmaTypography>
                    <CmaTypography variant='caption' className={classes.text}>Operating Portfolio</CmaTypography>
                    <CmaTypography variant='body2' className={classes.text}>Martin MASSON</CmaTypography>
                </div>
                <div ref={box4Ref} className={classes.node}>
                    <CmaTypography variant='h3' className={classes.text}>Chief Development Officer</CmaTypography>
                    <CmaTypography variant='caption' className={classes.text}>Business Development</CmaTypography>
                    <CmaTypography variant='body2' className={classes.text}>Dong GUI</CmaTypography>
                </div>
                <div ref={box5Ref} className={classes.node}>
                    <CmaTypography variant='h3' className={classes.text}>Chief Financial Officer</CmaTypography>
                    <CmaTypography variant='caption' className={classes.text}>Finance, Tax & Audit</CmaTypography>
                    <CmaTypography variant='body2' className={classes.text}>Crystal LU</CmaTypography>
                </div>
            </CmaGrid>

            <div style={{ position: 'relative' }}>
                <Xarrow
                    start={box1Ref}
                    end={box2Ref}
                    startAnchor={isMobile ? 'right' : 'bottom'}
                    endAnchor={isMobile ? 'left' : 'top'}
                    path='grid'
                    headSize={3}
                    strokeWidth={3}
                    color={Colors.colorFillPrimaryBlue}
                />
                <Xarrow
                    start={box1Ref}
                    end={box3Ref}
                    startAnchor={isMobile ? 'right' : 'bottom'}
                    endAnchor={isMobile ? 'left' : 'top'}
                    path='grid'
                    headSize={3}
                    strokeWidth={3}
                    color={Colors.colorFillPrimaryBlue}
                />

                <Xarrow
                    start={box1Ref}
                    end={box4Ref}
                    startAnchor={isMobile ? 'right' : 'bottom'}
                    endAnchor={isMobile ? 'left' : 'top'}
                    path='grid'
                    headSize={3}
                    strokeWidth={3}
                    color={Colors.colorFillPrimaryBlue}
                />

                <Xarrow
                    start={box1Ref}
                    end={box5Ref}
                    startAnchor={isMobile ? 'right' : 'bottom'}
                    endAnchor={isMobile ? 'left' : 'top'}
                    path='grid'
                    headSize={3}
                    strokeWidth={3}
                    color={Colors.colorFillPrimaryBlue}
                />
            </div>
        </CmaGrid>
    );
};

export default StructuredTreePage;