import axios from 'axios';
import Slide from '@mui/material/Slide';
import ReCAPTCHA from 'react-google-recaptcha';
import { CmaButton, CmaGrid, CmaTextField, CmaTextarea, CmaTypography, Colors } from '@cma-cgm/cma-ui';
import useStyles from './ContactUsPageStyles';
import { useEffect, useRef, useState } from 'react';
import { ToastState } from '../../shared/components/Toast/Interface';
import Toast from '../../shared/components/Toast/Toast';

function SlideTransition(props: any) {
    return <Slide {...props} direction="left" />;
}

const ContactUsPage = () => {
    const classes = useStyles();

    interface FormInterface {
        firstName: string,
        lastName: string,
        companyName: string,
        corporateEmail: string,
        message: string
    }

    const initialState = { firstName: '', lastName: '', companyName: '', corporateEmail: '', message: '' };
    const [state, setState] = useState<FormInterface>(initialState);
    const { firstName, lastName, companyName, corporateEmail, message } = state;

    const [toastState, setToastState] = useState<ToastState>({ open: false, message: '', severity: 'warning', Transition: SlideTransition, vertical: 'top', horizontal: 'right' });

    const [captchaChecked, setCaptchaChecked] = useState(false);
    const reRef = useRef<ReCAPTCHA>(null);

    const firstNameLength = 60;
    const lastNameLength = 60;
    const companyNameLength = 60;
    const corporateEmailLength = 60;
    const messageLength = 1000;

    const nameRegex = /[\[\]`'".,:;~=*{}()_/|<>%$\\^?!@#&~\d+]/g; //eslint-disable-line
    const messageRegex = /[\[\]~=*{}/|<>%$\\^@#&~]/g; //eslint-disable-line
    const emailRegex = /[\[\]`'",:;~=*{}()/|<>%$\\^?!#&+~]/g; //eslint-disable-line
    const emailValidationRegex = /^[\w-]+[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i; //eslint-disable-line

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onReCAPTCHAChange = async (captchaCode: any) => {
        if (!captchaCode) {
            setCaptchaChecked(false);
            return;
        }
        setCaptchaChecked(true);
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        let newValue = value;

        switch (name) {
            case 'firstName':
                newValue = value.replace(nameRegex, '');
                break;
            case 'lastName':
                newValue = value.replace(nameRegex, '');
                break;
            case 'companyName':
                newValue = value.replace(nameRegex, '');
                break;
            case 'corporateEmail':
                newValue = value.replace(emailRegex, '');
                newValue = newValue.replace(/\.{2,}/g, '.');
                break;
            case 'message':
                newValue = value.replace(messageRegex, '');
                break;
        }

        setState({ ...state, [name]: newValue.replace(/\s{2,}/g, ' ') });
    };

    const handleSubmit = async () => {
        const token = await reRef?.current?.getValue();
        if (token) {
            reRef?.current?.reset();

            const data = { ...state, token };

            await axios.post('/api/sendEmail', data).then((response: any) => {

                if (response.data.success) {
                    setToastState({ ...toastState, open: true, message: 'Your message was sent successfully', severity: 'success' });
                } else if (response.data.error) {
                    setToastState({ ...toastState, open: true, message: 'Failed to send values', severity: 'error' });
                }
            });
        } else {
            setToastState({ ...toastState, open: true, message: 'Make sure you are not a robot!', severity: 'error' });
        }

        setCaptchaChecked(false);
        setState(initialState);
    };

    const check = () => {
        if (!firstName || (firstName && firstName.length > firstNameLength) || (firstName === ' ') ||
            !lastName || (lastName && lastName.length > lastNameLength) || (lastName === ' ') ||
            !companyName || (companyName && companyName.length > companyNameLength) || (companyName === ' ') ||
            !corporateEmail || (corporateEmail && corporateEmail.length > corporateEmailLength) ||
            (corporateEmail && !emailValidationRegex.test(corporateEmail)) || (corporateEmail === ' ') ||
            !message || (message && message.length > messageLength) || (message === ' ') || !captchaChecked) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <Toast
                state={toastState}
                setState={setToastState}
            />
            <CmaGrid container display='flex' flexDirection='column' alignItems='center' justifyContent='center' className={classes.mainPage}>
                <CmaTypography variant='h1' textAlign='center' className={classes.title}>
                    WE WOULD LIKE TO HEAR FROM YOU
                </CmaTypography>

                <CmaGrid item container width='70%' gap={2} justifyContent='space-between'>
                    <CmaGrid item xs={12} md={5.5}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <CmaTypography variant='h4' className={classes.text}>
                                First name <span className={classes.label}>*</span>
                            </CmaTypography>

                            <CmaTypography
                                variant='caption'
                                className={classes.text}
                                sx={{
                                    color: (firstName.length > firstNameLength) ?
                                        `${Colors.colorFillPrimaryRed} !important` :
                                        `${Colors.colorFillPrimaryBlue} !important`
                                }}>
                                {firstName.length}/{firstNameLength}
                            </CmaTypography>
                        </div>

                        <CmaTextField
                            fullWidth
                            type='text'
                            name='firstName'
                            value={firstName || ''}
                            onChange={handleChange}
                            error={firstName && firstName.length > firstNameLength ? true : false}
                            helperText={firstName && firstName.length > firstNameLength && `First name cannot exceed ${firstNameLength} characters`}
                        />
                    </CmaGrid>

                    <CmaGrid item xs={12} md={5.5}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <CmaTypography variant='h4' className={classes.text}>
                                Last name <span className={classes.label}>*</span>
                            </CmaTypography>

                            <CmaTypography
                                variant='caption'
                                className={classes.text}
                                sx={{
                                    color: (lastName.length > lastNameLength) ?
                                        `${Colors.colorFillPrimaryRed} !important` :
                                        `${Colors.colorFillPrimaryBlue} !important`
                                }}>
                                {lastName.length}/{lastNameLength}
                            </CmaTypography>
                        </div>

                        <CmaTextField
                            fullWidth
                            type='text'
                            name='lastName'
                            value={lastName || ''}
                            onChange={handleChange}
                            error={lastName && lastName.length > lastNameLength ? true : false}
                            helperText={lastName && lastName.length > lastNameLength && `Last name cannot exceed ${lastNameLength} characters`}
                        />
                    </CmaGrid>

                    <CmaGrid item xs={12} md={5.5}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <CmaTypography variant='h4' className={classes.text}>
                                Company name <span className={classes.label}>*</span>
                            </CmaTypography>

                            <CmaTypography
                                variant='caption'
                                className={classes.text}
                                sx={{
                                    color: (companyName.length > companyNameLength) ?
                                        `${Colors.colorFillPrimaryRed} !important` :
                                        `${Colors.colorFillPrimaryBlue} !important`
                                }}>
                                {companyName.length}/{companyNameLength}
                            </CmaTypography>
                        </div>

                        <CmaTextField
                            fullWidth
                            type='text'
                            name='companyName'
                            value={companyName || ''}
                            onChange={handleChange}
                            error={companyName && companyName.length > companyNameLength ? true : false}
                            helperText={companyName && companyName.length > companyNameLength && `Company name cannot exceed ${companyNameLength} characters`}
                        />
                    </CmaGrid>

                    <CmaGrid item xs={12} md={5.5}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <CmaTypography variant='h4' className={classes.text}>
                                Corporate e-mail <span className={classes.label}>*</span>
                            </CmaTypography>

                            <CmaTypography
                                variant='caption'
                                className={classes.text}
                                sx={{
                                    color: (corporateEmail.length > corporateEmailLength) ?
                                        `${Colors.colorFillPrimaryRed} !important` :
                                        `${Colors.colorFillPrimaryBlue} !important`
                                }}>
                                {corporateEmail.length}/{corporateEmailLength}
                            </CmaTypography>
                        </div>

                        <CmaTextField
                            fullWidth
                            type='text'
                            name='corporateEmail'
                            value={corporateEmail || ''}
                            onChange={handleChange}
                            error={corporateEmail && !emailValidationRegex.test(corporateEmail) ? true :
                                corporateEmail && corporateEmail.length > corporateEmailLength ? true : false}
                            helperText={corporateEmail && !emailValidationRegex.test(corporateEmail) ?
                                'Corporate e-mail format is invalid' :
                                corporateEmail && emailValidationRegex.test(corporateEmail) && corporateEmail.length > corporateEmailLength &&
                                `Corporate e-mail cannot exceed ${corporateEmailLength} characters`}
                        />
                    </CmaGrid>

                    <CmaGrid item xs={12}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <CmaTypography variant='h4' className={classes.text}>
                                Message <span className={classes.label}>*</span>
                            </CmaTypography>

                            <CmaTypography
                                variant='caption'
                                className={classes.text}
                                sx={{
                                    color: (message.length > messageLength) ?
                                        `${Colors.colorFillPrimaryRed} !important` :
                                        `${Colors.colorFillPrimaryBlue} !important`
                                }}>
                                {message.length}/{messageLength}
                            </CmaTypography>
                        </div>

                        <CmaTextarea
                            maxRows={5}
                            minRows={5}
                            name='message'
                            value={message || ''}
                            onChange={handleChange}
                            error={message && message.length > messageLength ? true : undefined}
                            helperText={message && message.length > messageLength && `Message cannot exceed ${messageLength} characters`}
                            sx={{ width: 'calc(100% - 25px)', resize: 'none' }}
                        />
                    </CmaGrid>

                    <CmaGrid item xs={12} display='flex' justifyContent='center'>
                        <ReCAPTCHA
                            sitekey="6LfyJGcpAAAAAMOpfkm4gMOEyxvEwDVOYPXqSq3t"
                            size="normal"
                            ref={reRef}
                            onChange={onReCAPTCHAChange}
                            className={classes.captcha}
                        />
                    </CmaGrid>
                </CmaGrid>

                <CmaButton
                    variant='contained'
                    color='secondary'
                    disabled={check()}
                    onClick={handleSubmit}
                    sx={{ marginTop: '10px' }}>
                    Submit
                </CmaButton>
            </CmaGrid >
        </>
    );
};

export default ContactUsPage;