import { CmaGrid } from '@cma-cgm/cma-ui';
import useStyles from './MapNetworkPageStyles';
import L from 'leaflet';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { continentsList } from '../SelectLocation/SelectLocationsConstants';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiY3J5c3RhbG1vbmljYTk4IiwiYSI6ImNsaW84MGpvMjBkMjIzZHBjbG14OHY4NzAifQ.C4gcZeUMdZ3BnDJKVdEPjQ';

const MapNetworkPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClick = (id: number) => {
        navigate(`/ports/location/${id}`);
    };

    const customRedIcon = new L.Icon({
        iconUrl: '/icons/location.svg',
        iconSize: new L.Point(20, 27)
    });

    const ports = continentsList.flatMap((continent: any) =>
        continent.countriesList.flatMap((country: any) =>
            country.portsList.map((port: any) => ({
                id: port.id,
                name: port.name,
                latitude: port.latitude,
                longitude: port.longitude
            }))
        )
    );

    return (
        <CmaGrid container justifyContent='center' className={classes.mainPage}>
            <CmaGrid item container xs={12} justifyContent='center'>
                <MapContainer
                    center={[25.296398, 5.37]}
                    zoom={3}
                    scrollWheelZoom={true}
                    className={classes.mapContainer}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                        url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}&locale=en`}
                        id="mapbox/streets-v11"
                    />

                    <MarkerClusterGroup chunkedLoading>
                        {ports.map((port, index) => (
                            <Marker
                                icon={customRedIcon}
                                key={index}
                                position={[port.latitude, port.longitude]}
                                title={port.name}
                                eventHandlers={{
                                    click: () => handleClick(port.id)
                                }}
                            />
                        ))}
                    </MarkerClusterGroup>
                </MapContainer>
            </CmaGrid>
        </CmaGrid>
    );
};

export default MapNetworkPage;