import { Colors } from '@cma-cgm/cma-ui';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
	headerLogo: {
		width: '150px',
		cursor: 'pointer'
	},
	headerLogoHidden: {
		display: 'none'
	},
	sideMenuLogo: {
		width: '40%',
		padding: '25px'
	},
	headerLinks: {
		cursor: 'pointer',
		fontSize: '17px !important',
		fontWeight: '400',
		color: '#04246a',
		padding: '0 20px 0 10px',
		borderLeft: `3px solid ${Colors.colorFillPrimaryRed}`,
		'&:hover': {
			color: Colors.colorFillPrimaryRed,
			borderLeft: `3px solid ${Colors.colorFillPrimaryRed}`
		}
	},
	hoveredList: {
		borderLeft: `3px solid ${Colors.colorFillSecondaryGreyRegular}`
	},
	drawerMainLinks: {
		fontSize: 'calc(20px / 1.2) !important',
		fontWeight: 400,
		textDecoration: 'none',
		color: '#04246a !important',
		paddingRight: '20px',
		'&:hover': {
			color: 'red'
		}
	},
	active: {
		color: `${Colors.colorFillPrimaryRed} !important`
	},
	aboutListWrapper: {
		position: 'fixed',
		display: 'flex',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
		minHeight: '0',
		padding: '55px',
		top: '55px',
		right: '300px',
		zIndex: 1001,
		backgroundColor: 'white',
		borderTop: '1px solid #f3f5f7',
		visibility: 'hidden',
		transform: 'translateY(-100%)',
		transition: 'transform 0.4s ease-in-out, visibility 0s 0.4s',
		'@media (max-width: 600px)': {
			top: '55px'
		}
	},
	portsListWrapper: {
		position: 'fixed',
		display: 'flex',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
		minHeight: '0',
		padding: '55px',
		top: '55px',
		right: '220px',
		zIndex: 1001,
		backgroundColor: 'white',
		borderTop: '1px solid #f3f5f7',
		visibility: 'hidden',
		transform: 'translateY(-100%)',
		transition: 'transform 0.4s ease-in-out, visibility 0s 0.4s',
		'@media (max-width: 600px)': {
			top: '55px'
		}
	},
	open: {
		visibility: 'visible',
		transform: 'translateY(0%)',
		transition: 'transform 0.4s ease-in-out',
		boxShadow: '0 2px 4px grey',
		'@media (max-width: 1300px)': {
			visibility: 'visible',
			transform: 'translateY(0%)',
			transition: 'transform 0.4s ease-in-out'
		}
	},
	linksWrapper: {
		borderLeft: '3px solid red',
		margin: '10px 0 !important',
		'& a': {
			color: Colors.colorFillPrimaryBlue
		}
	},
	link: {
		padding: '0 20px 0 20px',
		borderLeft: '3px solid red',
		fontSize: 'calc(20px / 1.2) !important',
		fontWeight: '400',
		textDecoration: 'none',
		color: '#04246a !important',
		'&:hover': {
			color: Colors.colorFillPrimaryRed
		}
	},
	line: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '3px',
		height: '100%',
		backgroundColor: Colors.colorFillSecondaryGreyRegular
	},
	'@global': {
		'.submenu-title-enter': {
			opacity: 0,
			transform: 'translateY(-100px)'
		},
		'.submenu-title-enter-active': {
			opacity: 1,
			transform: 'translateY(0)',
			transition: 'opacity 700ms, transform 300ms ease-in-out'
		}
	}
}));

export default useStyles;