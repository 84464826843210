import { makeStyles } from '@mui/styles';
import { Colors } from '@cma-cgm/cma-ui';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width: 500px)': {
            marginLeft: '-32px'
        }
    },
    parent: {
        padding: '5px',
        borderRadius: '8px',
        display: 'inline-block',
        width: '125px',
        height: '70px',
        color: Colors.colorFillPrimaryBlue,
        border: `1px solid ${Colors.colorFillPrimaryRed}`,
        '@media (max-width: 900px)': {
            width: '125px'
        },
        '@media (max-width: 400px)': {
            width: '70px'
        }
    },
    node: {
        padding: '5px',
        borderRadius: '8px',
        display: 'inline-block',
        width: '200px',
        height: '100px',
        textAlign: 'center',
        color: Colors.colorFillPrimaryBlue,
        border: `1px solid ${Colors.colorFillPrimaryRed}`,
        '@media (max-width: 500px)': {
            padding: '2px',
            width: '160px'
        },
        '@media (max-width: 400px)': {
            width: '130px'
        }
    },
    text: {
        color: `${Colors.colorFillPrimaryBlue} !important`,
        textAlign: 'center',
        '@media (max-width: 500px)': {
            fontSize: '16px !important'
        },
        '@media (max-width: 400px)': {
            fontSize: '14px !important'
        }
    }
}));

export default useStyles;