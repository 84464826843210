import { Dispatch, SetStateAction } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { ToastState } from './Interface';
import { Alert } from '@mui/material';
import useStyles from './ToastStyles';

const Toast = (props: { state: ToastState; setState: Dispatch<SetStateAction<ToastState>>; }) => {
    const { state, setState } = props;

    const classes = useStyles();

    const { open, message, severity, Transition, vertical, horizontal } = state;
    const { name } = Transition;

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            message={message}
            autoHideDuration={3000}
            className={classes.anchorOriginTopRight}
            key={name}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Toast;