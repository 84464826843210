import { makeStyles } from '@mui/styles';
import { Colors } from '@cma-cgm/cma-ui';

const useStyles = makeStyles(() => ({
    mainPage: {
        minHeight: 'calc(100vh - 92.41px)',
        backgroundColor: Colors.colorBackgroundDefault,
        padding: '107.41px 0 15px 0'
    },
    title: {
        color: `${Colors.colorFillPrimaryBlue} !important`,
        height: '100px !important',
        marginBottom: '10px !important'
    },
    dash: {
        color: `${Colors.colorFillPrimaryRed} !important`
    },
    text: {
        color: `${Colors.colorFillPrimaryBlue} !important`
    },
    label: {
        color: Colors.colorFillPrimaryRed
    },
    captcha: {
        '@media (max-width: 845px)': {
            transform: 'scale(0.9)'
        }
    }
}));

export default useStyles;