import { CmaGrid, CmaTypography } from '@cma-cgm/cma-ui';
import useStyles from './AboutPageStyles';
import { aboutList } from './AboutConstants';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import StructuredTreePage from './StructuredTree/StructuredTreePage';

const AboutPage = () => {
    const classes = useStyles();
    const location = useLocation();

    const renderTextWithLinks = (text: any) => {
        const urlRegex = /(https?:\/\/[^\s)]+)/g;

        const segments = text.split(urlRegex);
        return segments.map((segment: any, index: number) => {
            if (segment.match(urlRegex)) {
                const url = segment.trim();
                return (
                    <a key={index} href={url} target='_blank' rel='noopener noreferrer'>
                        {url}
                    </a>
                );
            } else {
                return (
                    <span key={index}>{segment}</span>
                );
            }
        });
    };

    useEffect(() => {
        const scrollOffset = 95;

        if (location.hash) {
            const sectionId = location.hash.substring(1);
            const sectionElement = document.getElementById(sectionId);
            if (sectionElement) {
                const topOffset = sectionElement.offsetTop - scrollOffset;
                window.scrollTo({ top: topOffset, behavior: 'smooth' });
            }
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location]);

    return (
        <CmaGrid container justifyContent='center' className={classes.mainPage}>
            <CmaGrid item container xs={10} display='flex' gap={4} marginBottom={4}>
                {aboutList.map((aboutItem) => (
                    <CmaGrid
                        key={aboutItem.id}
                        xs={12}
                        id={aboutItem.id.toString()}
                        item
                        container
                        display='block'
                        direction='column'
                        gap={1}>
                        <CmaGrid item xs={1}>
                            <CmaTypography variant='h2' className={classes.title}>
                                {aboutItem.title}
                            </CmaTypography>
                        </CmaGrid>

                        {aboutItem.title === 'Management' ?
                            <CmaGrid item xs={9}>
                                <StructuredTreePage />
                            </CmaGrid> :
                            <CmaGrid item xs={9}>
                                {aboutItem.text.split('\n').map((line, index) => (
                                    <CmaTypography key={index} variant='body1' className={classes.text}>
                                        {<span className={classes.dash}>—</span>} {renderTextWithLinks(line)}
                                    </CmaTypography>
                                ))}
                            </CmaGrid>}
                    </CmaGrid>
                ))}
            </CmaGrid>
        </CmaGrid>
    );
};

export default AboutPage;