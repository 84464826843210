import React from 'react';
import './App.css';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import Layout from './shared/components/layout/Layout';
import HomePage from './modules/Home/HomePage';
import { FontStylesGlobal } from '@cma-cgm/cma-ui';
import NotFoundPage from './shared/components/NotFoundPage/NotFoundPage';
import MapNetworkPage from './modules/Ports/MapNetwork/MapNetworkPage';
import SelectLocationPage from './modules/Ports/SelectLocation/SelectLocationPage';
import AboutPage from './modules/About/AboutPage';
import LocationPage from './modules/Ports/Location/LocationPage';
import ContactUsPage from './modules/ContactUs/ContactUsPage';
import CareerPage from './modules/Careers/Career/CareerPage';
import CareerDescriptionPage from './modules/Careers/CareerDescription/CareerDescriptionPage';
import VideoPage from './modules/MediaKit/Video/VideoPage';
import ImageGalleryPage from './modules/MediaKit/ImageGallery/ImageGalleryPage';

function App() {
	const router = createBrowserRouter([
		{
			element: <Layout />,
			children: [
				{
					path: '/',
					element: <Navigate to='home' />
				},
				{
					path: 'home',
					element: <HomePage />
				},
				{
					path: 'about',
					element: <AboutPage />
				},
				{
					path: 'ports/network',
					element: <MapNetworkPage />
				},
				{
					path: 'ports/location',
					element: <SelectLocationPage />
				},
				{
					path: 'ports/location/:id',
					element: <LocationPage />
				},
				{
					path: 'media-kit/video',
					element: <VideoPage />
				},
				{
					path: 'media-kit/images',
					element: <ImageGalleryPage />
				},
				{
					path: 'contactUs',
					element: <ContactUsPage />
				},
				{
					path: 'career',
					element: <CareerPage />
				},
				{
					path: 'career/:id',
					element: <CareerDescriptionPage />
				},
				{
					path: '*',
					element: <NotFoundPage />
				}
			]
		}
	]);

	return (
		<div className="App">
			<FontStylesGlobal />
			<RouterProvider router={router} />
		</div>
	);
}

export default App;
