import { CmaGrid } from '@cma-cgm/cma-ui';
import useStyles from './VideoPageStyles';

const VideoPage = () => {
    const classes = useStyles();

    return (
        <CmaGrid container display='flex' alignItems='center' justifyContent='center' className={classes.mainPage}>
            <video src="/videos/video.mp4" controls className={classes.video} />
        </CmaGrid>
    );
};

export default VideoPage;