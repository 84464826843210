import useStyles from './NotFoundPageStyles';
import { CmaGrid, CmaTypography } from '@cma-cgm/cma-ui';

const NotFoundPage = () => {
    const classes = useStyles();

    return (
        <CmaGrid
            container
            item
            xs={12}
            display='flex'
            direction='column'
            alignItems='center'
            justifyContent='center'
            className={classes.content}>
            <CmaGrid item>
                <CmaTypography className={classes.title}>404</CmaTypography>
            </CmaGrid>
            <CmaGrid item>
                <CmaTypography className={classes.text}>Page Not Found</CmaTypography>
            </CmaGrid>
        </CmaGrid>
    );
};

export default NotFoundPage;