import { CmaButton, CmaGrid, CmaMenu, CmaMenuItem, CmaTypography } from '@cma-cgm/cma-ui';
import useStyles from './CareerDescriptionPageStyles';
import { careerList } from '../Career/CareerConstants';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

const CareerDescriptionPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const careerId: any = Number(location.pathname.split('/')[2]);
    const career: any = careerList.filter((careerItem: any) => careerItem.id === careerId)[0];

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (url: string) => {
        navigate(url);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <CmaGrid container justifyContent='center' className={classes.mainPage}>
            <CmaGrid xs={9} item container justifyContent='space-between' marginTop={4}>
                <CmaTypography variant='body1' className={classes.text}>
                    <span className={classes.section}>ID:</span> {career.id}
                </CmaTypography>

                <CmaButton color='secondary' onClick={handleClick} variant='outlined'>
                    Apply now
                </CmaButton>
                <CmaMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <CmaMenuItem onClick={() => { handleNavigate('/contactUs'); }}>
                        Contact Us
                    </CmaMenuItem>
                    <CmaMenuItem
                        onClick={() =>
                            window.open('https://jobs.cmacgm-group.com/CMACGM/job/Chief-Commercial-Officer-Terminal-Link/946088401/', '_blank')
                        }>
                        Apply Now
                    </CmaMenuItem>
                </CmaMenu>
            </CmaGrid>

            <CmaGrid xs={9} item container direction='column'>
                <CmaTypography variant='body1' className={classes.text}>
                    <span className={classes.section}>Location:</span> {career.location}
                </CmaTypography>
            </CmaGrid>

            <CmaGrid xs={12} item textAlign='center' marginTop={2}>
                <CmaTypography variant='h1' className={classes.jobTitle}>
                    {career.title}
                </CmaTypography>
            </CmaGrid>

            <CmaGrid xs={9} item container direction='column' marginTop={4}>
                {career.description.split('\n').map((line: string, index: number) => (
                    <CmaTypography key={index} variant='body1' className={classes.text}>
                        {line}
                    </CmaTypography>
                ))}
            </CmaGrid>

            {Object.entries(career.content).map(([key, value]: any, index) =>
                <CmaGrid key={index} xs={9} item container direction='column' marginTop={4}>
                    <CmaTypography variant='h2' className={classes.title}>
                        {key}
                    </CmaTypography>

                    <CmaGrid item container direction='column'>
                        {value.split('\n').map((line: string, index: number) => (
                            <CmaTypography key={index} variant='body1' className={line.startsWith('\t') ? clsx(classes.textSection, classes.text) : classes.text}>
                                {value.split('\n').filter((line: string) => line.startsWith('\t')).length === 1 && !line.startsWith('\t') && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                {(value.split('\n').length > 1) && !line.startsWith('\t') && <span className={classes.dash}>— </span>}
                                {line.split('\b').map((linetext, index: number) => (
                                    <React.Fragment key={index}>
                                        {(line.split('\b').length > 1 && index > 0) &&
                                            <span className={classes.dash}><br />&nbsp;&nbsp;&nbsp;{'\t'}— </span>}
                                        {linetext.split('\f').map((linetexts, index: number) => (
                                            <React.Fragment key={index}>
                                                {(line.split('\f').length > 1 && index > 0) &&
                                                    <span className={classes.dash}><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'\t'}— </span>} {linetexts}
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </CmaTypography>
                        ))}
                    </CmaGrid>
                </CmaGrid>
            )}

            <CmaGrid xs={9} item container direction='column'>
                {career.notes.split('\n').map((line: string, index: number) => (
                    <CmaTypography key={index} variant='body1' className={classes.text} marginTop={4}>
                        {line}
                    </CmaTypography>
                ))}
            </CmaGrid>
        </CmaGrid>
    );
};

export default CareerDescriptionPage;