import { CmaGrid, CmaTypography } from '@cma-cgm/cma-ui';
import useStyles from './SelectLocationPageStyles';
import { continentsList } from './SelectLocationsConstants';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const SelectLocationPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

    const handleClick = (id: number) => {
        const path = location.pathname.concat(`/${id}`);
        navigate(path);
    };

    return (
        <CmaGrid container justifyContent='center' className={classes.mainPage}>
            <CmaGrid item container xs={6} sm={11} justifyContent='center' alignItems='center'>

                {continentsList.map((continentListItem) => (
                    <CmaGrid key={continentListItem.id} item container xs={12} gap={2}>
                        <CmaTypography variant='h3' className={classes.continentName}>
                            {continentListItem.name}
                        </CmaTypography>
                        <CmaGrid item xs={12} display='flex' flexWrap='wrap' gap={2}>
                            {continentsList
                                .filter((continent) => continent.id === continentListItem.id)[0]
                                .countriesList
                                .map((countryItem: any) =>
                                    <CmaGrid
                                        key={countryItem.id}
                                        item
                                        container
                                        display='flex'
                                        direction='column'
                                        xs={12}
                                        sm={5}
                                        md={3}
                                        lg={1}
                                        gap={2}
                                        sx={{ minHeight: '120px', minWidth: '150px !important' }}>
                                        <CmaGrid item xs={1} textAlign='center'>
                                            <CmaTypography variant='h3' className={classes.countryName}>
                                                {countryItem.name}
                                            </CmaTypography>
                                        </CmaGrid>

                                        <CmaGrid item xs={7}>
                                            {countryItem.portsList.map((portItem: any) =>
                                                <CmaGrid
                                                    key={portItem.id}
                                                    item
                                                    marginBottom={1}
                                                    xs={12}
                                                    textAlign='center'
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => { handleClick(portItem.id); }}>
                                                    <CmaTypography variant='body1' className={classes.portName}>
                                                        {portItem.name}
                                                    </CmaTypography>
                                                </CmaGrid>
                                            )}
                                        </CmaGrid>
                                    </CmaGrid>
                                )}
                        </CmaGrid>
                    </CmaGrid>
                ))}
            </CmaGrid>
        </CmaGrid >
    );
};

export default SelectLocationPage;