import { makeStyles } from '@mui/styles';
import { Colors } from '@cma-cgm/cma-ui';

const useStyles = makeStyles(() => ({
    mainPage: {
        minHeight: 'calc(100vh - 92.41px)',
        backgroundColor: Colors.colorBackgroundDefault,
        padding: '107.41px 0 15px 0'
    },
    continentName: {
        color: `${Colors.colorFillPrimaryBlue} !important`
    },
    countryName: {
        border: `1px solid ${Colors.colorFillPrimaryRed}`,
        color: `${Colors.colorFillPrimaryBlue} !important`,
        padding: '2px 5px'
    },
    portName: {
        color: `${Colors.colorFillPrimaryBlue} !important`,
        backgroundColor: `${Colors.colorFillSecondaryGreyRegular} !important`,
        padding: '2px 5px'
    }
}));

export default useStyles;