export const images = [
    '/images/homepage5.jpg',
    '/images/homepage4.jpg',
    '/images/homepage6.jpg',
    '/images/homepage2.jpg',
    '/images/homepage3.jpg',
    '/images/homepage1.jpg',
    '/images/homepage7.jpg',
    '/images/homepage8.jpg',
    '/images/homepage9.jpg'
];