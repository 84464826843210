export const continentsList = [
    {
        id: 1,
        name: 'Europe & Africa',
        countriesList: [
            {
                id: 11,
                name: 'Netherlands',
                portsList: [
                    {
                        id: 111,
                        name: 'Rotterdam',
                        latitude: 51.95314391024843,
                        longitude: 3.9917530845598326,
                        image: '/images/Rotterdam.jpg',
                        advantages: 'Fully automated deep water terminal, operating in the port of Rotterdam since 2015.\nStrategically located in the largest logistic hub in Northern European, designed for accommodating ULCS (ultra large container ship).\nOffering the best international practices in terms of productivity and efficiency with fully-automated yard.\nProviding excellent waterways connections.',
                        markets: 'One of the most advanced terminals located in the most important port in Europe.\nComprehensive intermodal network, connecting the port to the European hinterland through road, rail and barge.\nOver 200 rail connections to eleven countries:  France, Germany, Switzerland, Poland, Italy and Central European markets.\nApproximately 500 barge services provided by approximately 50 operators serving six markets:  Netherlands, Belgium, Germany, France, Luxembourg and Switzerland.',
                        content: {
                            title: 'Rotterdam World Gateway (RWG) - Rotterdam',
                            image: '/images/RotterdamCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '1500 meters (main)',
                                        '500 meters (barge)'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '13 STS Cranes',
                                        '3 barge Cranes'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '84 AVGs',
                                        '50 ASC'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Draft',
                                    values: [
                                        '20 meters',
                                        '11 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '108 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 2.400k TEUs'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 12,
                name: 'Belgium',
                portsList: [
                    {
                        id: 121,
                        name: 'Antwerp',
                        latitude: 51.283660785376824,
                        longitude: 4.265917628735926,
                        image: '/images/Antwerp.jpg',
                        advantages: 'One of the main terminals in the port of Antwerp with ample connections to the European hinterland by rail and barge.\nThe only container terminal in northern Europe able to efficiently operates with Automated Stacking Crane since 2008.\nLocated on the left bank of the river without the need to pass locks, AGW is ready to serve the biggest container vessels with capacity above 20.000 TEUs with 25 containers wide.',
                        markets: 'Located in the second most important European port, Antwerp serves its domestic market, and transit markets of Germany France, Switzerland and central Europe.\nAntwerp is well connected by a comprehensive rail and barge network serving the European market with regular inland services.',
                        content: {
                            title: 'Antwerp Gateway (AGW) - Antwerp',
                            image: '/images/AntwerpCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '1660 meters (main)'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '13 STS Cranes',
                                        '1 MHC'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '20 ASC, 1 RMG',
                                        '67 Straddle Carriers'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Draft',
                                    values: [
                                        '16 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '107 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 2.800k TEUs'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 13,
                name: 'France',
                portsList: [
                    {
                        id: 131,
                        name: 'Dunkrik',
                        latitude: 51.01402407813556,
                        longitude: 2.1852316826195084,
                        image: '/images/Dunkrik.jpg',
                        advantages: 'Dunkirk serves a rich hinterland of north of France, representing an estimate volume in excess of 1 million TEUs, excluding Paris region.\nStable labour relationships, allowing consignees and shippers to rely on stable supply chains even during the pandemic period. Only French port with ZERO strikes in the last 20 years.\nExcellent road, rail and barge network to serve one of Europe’s richest hinterland.\nHome of some of the main French Consignees.\nImportant logistic & industrial area near the port. New investments already announced expected to significantly increase domestic volumes within coming years.',
                        markets: 'Dunkirk serves a rich hinterland consisting of Northwest and Northeast of France, as well as the Paris region.\nRegular trains to and from Dourges, Paris and Metz.\nBarge connections to Lille, Dourges and Valenciennes.\nMain gateway for reefer cargo from Latin America and French overseas territories with distribution to the whole country and other European countries.\nDunkirk and its hinterland will be home to significant new logistic & industrial investments such as Verkor new Gigafactory.\nDunkirk is also a major transhipment hub to and from the West Coast of UK and Ireland.',
                        content: {
                            title: 'Terminal des Flandres (TDF) - Dunkrik',
                            image: '/images/DunkrikCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '1745 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '8 STS Cranes'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '11 Reach Stackers'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Depth',
                                    values: [
                                        '13.3 to 17.3 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '52 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 900k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    },
                    {
                        id: 132,
                        name: 'Le Havre',
                        latitude: 49.46254230943935,
                        longitude: 0.164836189121068,
                        image: '/images/LeHavre.jpg',
                        advantages: 'One of the biggest ports in North Europe and first European port call on Westbound and direct access from the sea.\nGMP operates two main facilities in the port are: North Terminals (Europa and Americas quay) and Terminal de France (TdF).\nMain gateway to the French market, handling 2million TEUs per year.\nThe only facility in France able to offer 10 Super Post-Panamax cranes, handling the last generation container vessels.\nExcellent hinterland connections to the French market, supported by 5Km of railway facilities  and 3 RMG (Rail Mounted Gantries).',
                        markets: 'Main gateway to Paris region, Central & Southwest of France.\nOver 40 barge departures per week connecting to and from nine inland points in France.\nOver 70 weekly train departures to and from sixteen destinations in France, plus Switzerland and Italy.',
                        content: {
                            title: 'Le Havre - Générale de Manutention Portuaire (GMP)',
                            image: '/images/LeHavreCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        'TDF: 1400 meters',
                                        'TN: 1400 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        'TDF: 10 STS Cranes',
                                        'TDF: 6 STS Cranes + 1 MHC'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        'TDF: 71 SC, 3 RMG',
                                        'TN: 14 SC'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Depth',
                                    values: [
                                        'TDF: 15.5 meters',
                                        'TN: 14.5 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        'TDF: 80 Ha',
                                        'TN: 70 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design TDF: 1.600k TEU',
                                        'Design TN: 500k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    },
                    {
                        id: 133,
                        name: 'Montoir',
                        latitude: 47.29839975708286,
                        longitude: -2.157289910182205,
                        image: '/images/Montoir.jpg',
                        advantages: 'Preferred gateway to serve the dynamic market of the west of France, one of the strongest export-oriented regions of France, leader in Agri-food business.\nExcellent connections by road, rail, barge enabling easy dispatch of boxes in the neighbourhood of Montoir Terminal. Market positioning for small vessels up to 6,000 TEUs, serving an important niche market.\nLogistic activities for wind turbines delivered at General Electric nearby plant.\nWarehousing and (un)stuffing activities inside the port.',
                        markets: 'TGO serves a captive hinterland consisting of the provinces of Loire, Bretagne, Centre Val Loire and Nouvelle-Aquitaine, consisting of 15.8m people.\nRelevant agricultural and food industry strongly export-oriented to Europe and overseas countries.\nTGO is connected by rail to the French railway network.\nBarge connections in the Loire river.',
                        content: {
                            title: 'Terminal du Grand Ouest (TGO) - Montoir',
                            image: '/images/MontoirCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '950 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '4 STS Cranes',
                                        '2 MHC'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '10 Reach Stackers',
                                        '12 Trucks'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Depth',
                                    values: [
                                        '13.5 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '58 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 500k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    },
                    {
                        id: 134,
                        name: 'Fos',
                        latitude: 43.40707016604352,
                        longitude: 4.844653649028585,
                        image: '/images/Marseille.jpg',
                        advantages: 'Eurofos is the main container terminal in FOS, serving a vast and rich hinterland consisting of the southwest and Southeast of France.\nIdeally situated in one of the biggest industrial complexes in the Mediterranean with potential for expansion thanks to the existing space availability.\nEurofos has natural conditions and is equipped to handle the latest-generation container vessels.',
                        markets: 'Excellent hinterland connections to Rhône-Alpes region in Southern France.\nFOS is the gateway for the Southern part of France covering the provinces of PACA (Provence-Alpes-Cote d’Azur), OCCITANIE and AUVERGNE-ROHNES-ALPES (Lyon area).\nRegular trains connecting FOS to its hinterland and to transit markets like Switzerland and Germany.\nDaily barge connections through the Rhone river serving the area of Lyon, one of the most important markets of France.',
                        content: {
                            title: 'Marseille - Fos',
                            image: '/images/MarseilleCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '1600 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '7 STS Cranes',
                                        '1 MHC'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '55 Straddle Carriers',
                                        '3 Reach stackers'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Depth',
                                    values: [
                                        '14-16 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '82 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 1000k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 14,
                name: 'Malta',
                portsList: [
                    {
                        id: 141,
                        name: 'Malta Freeport',
                        latitude: 35.81633346248572,
                        longitude: 14.53786109336866,
                        image: '/images/Malta.jpg',
                        advantages: 'Leading transhipment hub in the Mediterranean, ideally located at the heart of the strategic Suez – Gibraltar route and connected with 130 ports in the Mediterranean, North Africa and Black Sea.\nNo deviation from main shipping route, providing easy access and favourable nautical conditions.\nThe port is equipped to efficiently handle Ultra Large Container Vessels of >20.000 TEUs.\nHigh productivity levels and quick vessel turnaround.',
                        markets: 'Central distribution hub to and from the Tyrrhenian and Adriatic Sea, North Africa (Algiers, Libya, Tunisia and Egypt) Aegean and Black Sea.\nExtensive hub-and-spoke operations supported by a comprehensive feeder network, as well relay transhipment operations between East-West trades such as India-East Coast North America.',
                        content: {
                            title: 'Malta Freeport Terminal (MFTL) - Malta',
                            image: '/images/MaltaCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '2463 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '22 STS Cranes'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '50 RTG',
                                        '135 Trucks'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Depth',
                                    values: [
                                        '17 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '77 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 3600k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 15,
                name: 'Greece',
                portsList: [
                    {
                        id: 151,
                        name: 'Thessaloniki',
                        latitude: 40.63557715698247,
                        longitude: 22.923134220377886,
                        image: '/images/Thessaloniki.jpg',
                        advantages: 'Gateway port to strong export-oriented market of Northern Greece.\nTransit port serving an hinterland of 20 million people consisting of the Southern Balkan countries of Bulgaria, North Macedonia, Serbia, Albania and Kosovo.\nClose to major motorways and railway networks.\nServe all types of cargo as well as passenger traffic.',
                        markets: 'Thessaloniki is the main gateway of Central and Northern Greece, serving the most important export market of the country.\nGateway to land-locked North Macedonia, Western Bulgaria and Southern Serbia.\nCaptive market consisting of between 15-20m people.\nWeekly train departures to Sofia (Bulgaria) and NIS (Serbia).',
                        content: {
                            title: 'Port of Thessaloniki (THPA) - Greece',
                            image: '/images/ThessalonikiCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        'Container 500 m'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '5 STS',
                                        '2 MHC'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '20 Straddle Carriers',
                                        '2 Reach Stackers',
                                        '2 Front loaders'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Depth',
                                    values: [
                                        '13.5 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '32 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 550k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 16,
                name: 'Ukraine',
                portsList: [
                    {
                        id: 161,
                        name: 'Odessa',
                        latitude: 46.50865602316837,
                        longitude: 30.727967056917887,
                        image: '/images/Odessa.jpg',
                        advantages: 'Ideally located in the largest Ukrainian seaport, which is also one of largest ports of the Black Sea basin.\nMajor import / export gate for the fast growing Ukrainian domestic cargo.\nCapable of handling vessels up to 300mts length.\nRail handling operations are arranged on a newly developed area with its own railroad side tracks that make it possible to handle 21-flatcar trains.',
                        markets: 'Odessa is the main port of Ukraine, serving as gateway for the whole country.\nMain export gateway for the Agricultural goods produced by the country.\nOnly port benefiting from direct service to and from Asia calling BKP Terminal.',
                        content: {
                            title: 'Brooklyn Kiev Port (BKP) - Odessa',
                            image: '/images/OdessaCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '480 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '3 STS Cranes'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '14 Reach Stackers'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Draft',
                                    values: [
                                        '13 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '11 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 280k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                },
                                {
                                    name: 'Terminal Operating System',
                                    values: [
                                        'SOLVO'
                                    ],
                                    icon: '/icons/terminalOperatingSystem.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 17,
                name: 'Morocco',
                portsList: [
                    {
                        id: 171,
                        name: 'Tangier',
                        latitude: 35.892421521823366,
                        longitude: -5.486837738868519,
                        image: '/images/Tangier.jpg',
                        advantages: 'Preferred hub in the Mediterranean sea, strategically located at the intersection between the East-West and North-South Atlantic routes without any deviation.\nRelay transhipment connections to and from West Coast of Africa and East Coast of South America.\nHub-and-spoke transhipment connections to and from Morocco, and northern part of Africa.\nOffering a Free Zone adjacent to Tangier Med Complex.\nAble to accommodate and handle the last-generation Container Ships of >20.000 TEUs.\nProviding well qualified workforce with high levels of efficiency and productivity consistently around 30 moves per hour/crane.',
                        markets: 'Leading Transhipment hub connecting East-West with North-South Atlantic trades.\nExtensive coverage of West Africa market, South America and US East Coast.\nGateway to Morocco market with weekly rail departures to Casablanca and Marrakech.',
                        content: {
                            title: 'Eurogate Tangier (EGT) - Tangier',
                            image: '/images/TangierCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '812 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '8 STS Cranes',
                                        '1 MHC'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '21 RTG',
                                        '6 Reach Stackers'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Depth',
                                    values: [
                                        '12 to 18 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '40 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 1600k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    },
                    {
                        id: 172,
                        name: 'Casablanca',
                        latitude: 33.60208741583824,
                        longitude: -7.602942465125855,
                        image: '/images/Casablanca.jpg',
                        advantages: 'Only private terminal in the port, offering high-standard services to Shipping Lines, Shippers & consignees.\nCasablanca is the country’s major gateway, accounting for 30% of the GDP of the country and 44% of industrial production.\nDedicated areas to handle containers. Besides, Somaport also has dedicated area for the handling of general cargo, break bulk and RO-RO cargo.\nSomaport can handle the biggest container vessels calling the port, offering modern facilities and brand-new equipment, delivering high levels of productivity.',
                        markets: 'Casablanca is the main consumption and production centre of Morocco, representing almost one third of the country’s GDP.\nThrough Casablanca, other important areas of the market are served, such as Rabat and Marrakesh.',
                        content: {
                            title: 'Somaport - Casablanca',
                            image: '/images/CasablancaCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '700 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '3 STS Cranes',
                                        '3 MHC'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '10 RTG',
                                        '3 Reach Stackers',
                                        '4 empty handler',
                                        '1 top lifter',
                                        '15 forklift 3-32t'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Depth',
                                    values: [
                                        '10 meters (containers)',
                                        '9 meters (General cargo)'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '20 Ha (containers)',
                                        '14 Ha (General cargo)'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 450k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 18,
                name: 'Ivory Coast',
                portsList: [
                    {
                        id: 181,
                        name: 'Cote d Ivoire',
                        latitude: 5.283508015082068,
                        longitude: -4.0107293862996825,
                        image: '/images/Abidjan.jpg',
                        advantages: 'Offering the only dedicated area in Ivory Coast for RO-RO vessels, handling containers, bolsters, cars, commercial vehicles and wood.\nStrategically located in the economic capital of Ivory Coast, the major gateway for domestic and transit cargo.',
                        markets: 'Dedicated facility for the handling of Roll on-Roll off cargo',
                        content: {
                            title: 'Terminal Roulier d Abidjan (TERRA) - Abidjan',
                            image: '/images/AbidjanCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '400 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        'nil'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '6 Reach Stackers',
                                        '10 tugs'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Depth',
                                    values: [
                                        '11 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '8 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        '70k TEU (container)',
                                        '712k tons (General cargo)'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        name: 'Asia & Middle East',
        countriesList: [
            {
                id: 21,
                name: 'Iraq',
                portsList: [
                    {
                        id: 211,
                        name: 'Umm Qasr',
                        latitude: 30.025295121311736,
                        longitude: 47.948892094609604,
                        image: '/images/UmmQASR.jpg',
                        advantages: 'First container terminal at the entrance of the navigation channel.\nHigh level of productivity, sustained by two brand-new STS commissioned in November 2022.\nOperations provided round-the-clock and ample storage capacity.\nEnjoying the same customs regime as other terminals in the port.\nClose to the main Highway to Basra and to the major oil fields in Southern Iraq.\nClose to the Umm Qasr Logistics Centre, fully integrated logistics services bonded facility.',
                        markets: 'Gateway for the whole market of Iraq, including Basra, Baghdad and Northern Iraq.',
                        content: {
                            title: 'Umm Qasr CCTI - UMM QASR',
                            image: '/images/UmmQASRCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '230 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '2 STS Cranes'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '10 Reach Stackers',
                                        '5 Empty handlers'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Draft',
                                    values: [
                                        '10.5 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '10 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 400k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                },
                                {
                                    name: 'Terminal operating System',
                                    values: [
                                        'TSB CATOS'
                                    ],
                                    icon: '/icons/terminalOperatingSystem.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 22,
                name: 'Vietnam',
                portsList: [
                    {
                        id: 221,
                        name: 'VICT',
                        latitude: 10.759047197708918,
                        longitude: 106.73715602119522,
                        image: '/images/HoChiMinh.jpg',
                        advantages: 'Strategically located in Ho Chi Minh City port cluster, closed to main IDC’s and key origin/destinations, serving one of the fastest growing markets in Asia.\nVICT is one of the three main container terminals in Ho Chi Minh city. The terminal has available capacity to accommodate new services providing berth upon arrival and quick vessel turnaround.\nSuitable location for trucks and waterway connections to industrial zones avoiding city centre traffic jams.\nAuxiliary services provided to customers, such as depot service for empty containers storage, pick-up and return and CFS services.\nHigh-quality customer base, including international carriers, domestic shipping companies and large consignees.',
                        markets: 'Serving Vietnam’s most vibrant market of over 8m TEUs per year.\nHo Chi Minh is Vietnam’s biggest consumption and production centre with approximately 10m inhabitants.',
                        content: {
                            title: 'Vietnam International Container Terminal (VICT) - Ho Chi Minh',
                            image: '/images/HoChiMinhCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '678 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '7 STS Cranes'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '10 RTG',
                                        '2 RS, 4 ECH'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Draft',
                                    values: [
                                        '11.2 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '20 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 800k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 23,
                name: 'Thailand',
                portsList: [
                    {
                        id: 231,
                        name: 'Laem Chabang',
                        latitude: 13.056655365016175,
                        longitude: 100.89108978420683,
                        image: '/images/LaemChabang.jpg',
                        advantages: 'LCIT consists of two terminals, B5 and C3.\nSecond largest terminal in Laem Chabang in terms of capacity.\nEasy access to channel leading to faster, easier and safer vessel movements during arrivals and departures, compared to competitor terminals.\nC3 terminal is among the deepest of Laem Chabang port, able to accommodate the largest vessels calling the port with capacity over 8.000 TEUs.\nVery diversified and solid customer base, driven by high quality of service and productivity.\nOff-dock empty depot to handle empty containers.\nServing Thailand’s vibrant market, one of the most dynamic in South East Asia.',
                        markets: '',
                        content: {
                            title: 'Laem Chabang Container International Terminal (LCIT) - Laem Chabang',
                            image: '/images/LaemChabangCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '400 meters',
                                        '500 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '9 STS'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '27 RTGs',
                                        '4 Reach stackers'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Draft',
                                    values: [
                                        '14 meters',
                                        '16 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '38 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 1800k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 24,
                name: 'Singapore',
                portsList: [
                    {
                        id: 241,
                        name: 'Singapore',
                        latitude: 1.275445169912738,
                        longitude: 103.79177762945403,
                        image: '/images/Singapore.jpg',
                        advantages: 'Located in the Port of Singapore, the world’s busiest transhipment hub.  Singapore offers connectivity to over 600 ports in 123 countries.\nJV between CMA CGM-PSA (CPLT) using and operating four mega container berths at Pasir Panjang Terminal Phases 3 and 4 in Singapore.\nMajor transhipment hub connecting with South East Asia and India Sub Continent market.',
                        markets: '',
                        content: {
                            title: 'CPLT Terminal - Singapore',
                            image: '/images/SingaporeCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '1705 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '19 STS Cranes'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '57 ARNGs',
                                        '4 ECH'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Draft',
                                    values: [
                                        '18 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '84 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 4000k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 25,
                name: 'China',
                portsList: [
                    {
                        id: 251,
                        name: 'Qingdao',
                        latitude: 35.99387993261674,
                        longitude: 120.219385500077,
                        image: '/images/Qingdao.jpg',
                        advantages: 'State-of-the-art terminal, located in the world’s 5th biggest container port.\nDeep-water terminal, equipped with Ship-to-shore cranes to handle sixth generation mega-vessels.\nWell-developed intermodal transportation network (comprising rail, road and waterways), connecting to affluent Shandong province and Northern China’s economic area (Beijing, Tianjin and Bohai).',
                        markets: 'Shandong province is China’s second most populous province in China with over 100 million people.\nIt is a leading province in food production and is China’s 4th largest export region.\nQingdao port processes over 25m TEUs per year.',
                        content: {
                            title: 'QINGDAO Terminal – Qingdao Qianwan United Container Terminal',
                            image: '/images/QingdaoCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '660 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '7 STS Cranes'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '16 RMGs',
                                        '2 RS / 1 ECH'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Draft',
                                    values: [
                                        '20 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '52 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 1300k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 26,
                name: 'South Korea',
                portsList: [
                    {
                        id: 261,
                        name: 'Busan',
                        latitude: 35.064693455386085,
                        longitude: 128.81969869913996,
                        image: '/images/Busan.jpg',
                        advantages: 'Terminal of reference in South Korea’s main gateway port.\nIdeally located in Busan port, enjoying favourable nautical conditions.\nBNCT is capable of accommodating and handling the latest generation of container vessels (> 20,000 TEU).\nSemi automated terminal with a perpendicular layout achieving a high and still increasing productivity: average 35 gross mph on mother vessels.',
                        markets: 'Main gateway to serve South Korea’s busiest hinterland of over 20 million TEUs p.a.\nBusan is also key transhipment hub to serve northern Asia market.',
                        content: {
                            title: 'Busan New Container Terminal (BNCT) – Busan',
                            image: '/images/BusanCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '1400 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '11 STS Cranes'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '42 ARNGs',
                                        '28 Straddle Carriers'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Draft',
                                    values: [
                                        '17 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '84 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 3200k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        name: 'Americas',
        countriesList: [
            {
                id: 31,
                name: 'USA',
                portsList: [
                    {
                        id: 311,
                        name: 'Miami',
                        latitude: 25.767092220005104,
                        longitude: -80.1468451637009,
                        image: '/images/Miami.jpg',
                        advantages: 'Excellent strategic location, serving as hub for the Latin American trade.\nMain gateway from Asia to South Florida market.\nOffers good road access through the dedicated tunnel to the Port of Miami, connecting to the Interstate Highway.\nOn-dock rail site giving access to the northern part of US market.',
                        markets: 'South Florida Container Terminal, known as SFCT is the gateway to the buoyant market of South Florida, one of the fastest growing state in population in the USA.\nSFCT is conveniently located close to the main warehousing & distribution areas in South Florida located in Doral, just 25km away for the port.\nUnrivalled conditions to handle the biggest container ships, due to dredging plan executed by the Port of Miami.\nOnly terminal in South Florida converting yard operations from reach stackers into E-RTGs.',
                        content: {
                            title: 'South Florida Container Terminal (SFCT) – Miami',
                            image: '/images/MiamiCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '1461 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '13 STS Cranes'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '23 Top Loaders'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Draft',
                                    values: [
                                        '15.2 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '33 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 450k TEU',
                                        'Operational: 383k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    },
                    {
                        id: 312,
                        name: 'Houston',
                        latitude: 29.60560313886837,
                        longitude: -95.0061887547665,
                        image: '/images/Houston.jpg',
                        advantages: 'Ideally located in the US Gulf since the Port of Houston handles 70% of the containerized cargo market in the U.S. Gulf and 96% of the containers moved through Texas seaports.\nGateway to Texas, mostly handling local cargo (O/D), in particular the important oil industry.\nExcellent railway connections, as a hub between West and East Coast and Mid-West.\nTL Texas provide stevedoring services, horizontal transfer from quay to yard.\nAuxiliary services include own depot services for empty containers, CFS, handling of OOGs & oilfield-related machinery.',
                        markets: 'TL Texas serves one of the fastest growing gateways to US market, with increasing volumes shifted from west coast, choosing to enter US market through the port of Houston.',
                        content: {
                            title: 'Terminal Link Texas (TLT) – Houston',
                            image: '/images/HoustonCharacteristics.png',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '1067 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '9 STS Cranes'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '27 RTG',
                                        '6 Empty Handlers'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Draft',
                                    values: [
                                        '13.7 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '10.1 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 1000k TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                id: 32,
                name: 'Jamaica',
                portsList: [
                    {
                        id: 321,
                        name: 'Kingston',
                        latitude: 17.991809014451437,
                        longitude: -76.83329896237032,
                        image: '/images/Kingston.jpg',
                        advantages: 'Central location at the heart of the Caribbean market between Panama Canal and US East Coast ports, with minimum deviation to shipping lines.\nOne of the biggest hubs in the Caribbean market, acting as major hub-and-spoke to Caribbean countries, USA and Gulf of Mexico.\nAble to accommodate the largest vessels passing through the enlarged Panama Canal.\nLogistic services to cargo and containers such as maintenance & repair.\nA multi-liner hub handling different lines and services from different alliances.',
                        markets: 'Major hub-and-spoke transhipment hub serving the Caribbean market.\nRelay transhipment operations connecting East/West services (to/from Asia) with North/South services (U.S. East Coast to East Coast of South America).',
                        content: {
                            title: 'Kingston Freeport Terminal Ltd (KFTL) - Kingston',
                            image: '/images/KingstonCharacteristics.jpg',
                            terminalCharacteristics: [
                                {
                                    name: 'Quay Length',
                                    values: [
                                        '2400 meters'
                                    ],
                                    icon: '/icons/quayLength.jpg'
                                },
                                {
                                    name: 'Quay Cranes',
                                    values: [
                                        '16 STS Cranes'
                                    ],
                                    icon: '/icons/quayCranes.jpg'
                                },
                                {
                                    name: 'Yard Equipment',
                                    values: [
                                        '65 straddle carriers'
                                    ],
                                    icon: '/icons/yardEquipment.jpg'
                                },
                                {
                                    name: 'Draft',
                                    values: [
                                        '14.7 meters'
                                    ],
                                    icon: '/icons/depth.jpg'
                                },
                                {
                                    name: 'Terminal Area',
                                    values: [
                                        '106 Ha'
                                    ],
                                    icon: '/icons/terminalArea.jpg'
                                },
                                {
                                    name: 'Terminal Capacity',
                                    values: [
                                        'Design: 3200 TEU'
                                    ],
                                    icon: '/icons/terminalCapacity.jpg'
                                },
                                {
                                    name: 'Terminal operating System',
                                    values: [
                                        'NAVIS N4'
                                    ],
                                    icon: '/icons/terminalOperatingSystem.jpg'
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    }
];