import { makeStyles } from '@mui/styles';
import { Colors } from '@cma-cgm/cma-ui';

const useStyles = makeStyles(() => ({
    mainPage: {
        minHeight: 'calc(100vh - 92.41px)',
        backgroundColor: Colors.colorBackgroundDefault,
        padding: '107.41px 0 15px 0'
    },
    section: {
        backgroundColor: Colors.colorFillSecondaryGreyExtraLight
    },
    terminalCharacteristics: {
        width: '100%',
        height: '150px',
        objectFit: 'cover'
    },
    title: {
        color: `${Colors.colorFillPrimaryBlue} !important`
    },
    subtitle: {
        margin: '10px 0 !important',
        color: `${Colors.colorFillPrimaryBlue} !important`
    },
    content: {
        width: '70%',
        '@media (max-width: 900px)': {
            width: '50%'
        },
        '@media (max-width: 600px)': {
            width: '90%'
        }
    },
    icon: {
        width: '40px',
        height: '40px',
        marginTop: '5px !important'
    },
    mainImage: {
        width: '100%',
        height: '250px',
        objectFit: 'cover'
    },
    singaporeImage: {
        height: '250px',
        objectFit: 'contain'
    },
    image: {
        width: '100%',
        height: '250px',
        objectFit: 'cover'
    },
    smallImage: {
        width: '100%',
        height: '180px',
        objectFit: 'cover'
    },
    dash: {
        color: `${Colors.colorFillPrimaryRed} !important`
    },
    text: {
        color: `${Colors.colorFillPrimaryBlue} !important`
    }
}));

export default useStyles;