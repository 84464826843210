import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tables: []
};

export const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    setTables: (state, action: { payload: any }) => {
      state.tables = action.payload;
    }
  }
});
export const { setTables } = tablesSlice.actions;
export default tablesSlice.reducer;