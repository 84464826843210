import { makeStyles } from '@mui/styles';
import { Colors } from '@cma-cgm/cma-ui';

const useStyles = makeStyles(() => ({
    content: {
        minHeight: 'calc(100vh - 92.41px)',
        backgroundColor: Colors.colorBackgroundDefault,
        padding: '107.41px 0 15px 0'
    },
    title: {
        color: Colors.colorFillPrimaryBlue,
        padding: '20px 5px',
        fontSize: '80px !important'
    },
    text: {
        color: Colors.colorFillPrimaryBlue,
        padding: '30px 5px',
        fontSize: '40px !important'
    }
}));

export default useStyles;