import { Outlet } from 'react-router-dom';
import HeaderCmpt from './Header';
import FooterCmpt from './Footer/Footer';

const Layout = () => {

	return (
		<>
			<HeaderCmpt />
			<Outlet />
			<FooterCmpt />
		</>
	);
};

export default Layout;